class TextCommon {
    public static readonly SIGN_IN_HEADER_TITLE = `Sign-In Information`;
    public static readonly HEADER_TITLE = `AIMS Cloud Service`;
    public static readonly APP_TITLE = `AIMs Data Importer`;
    public static readonly PICKER_TITLE = `Aims Data Import`;
    public static readonly SCANNING = `Scanning...`;
    public static readonly NO = `No.`;
    public static readonly BUCKET = `Bucket`;
    public static readonly TOTAL_FILE = `Total File(s)`;
    public static readonly TOTAL_SIZE = `Total Size(s)`;
    public static readonly EQUIPMENT = `Equipment`;
    public static readonly FILE = `File`;
    public static readonly SIZE = `Size`;
    public static readonly DATE = `Date`;
    public static readonly FOLDER = `Folder`;
    public static readonly PROGRESS = `Progress`;
    public static readonly SIGN_OUT = `Sign Out`;
    public static readonly BROWSE = `Browse`;
    public static readonly AGREE = `Agree`;
    public static readonly DISAGREE = `Disagree`;
    public static readonly CLOSE = `Close`;
    public static readonly CANCEL = `Cancel`;
    public static readonly CREATE = `Create`;
    public static readonly SAVE = `Save`;
    public static readonly CONFIRM = `Confirm`;
    public static readonly DELETE = `Delete`;
    public static readonly YES_DELETE = `Yes, Delete`;
    public static readonly UPLOAD = `Upload`;
    public static readonly DROP = `Drop`;
    public static readonly DRAG = `Drag`;
    public static readonly SELECT = `Select`;
    public static readonly OR = `or`;
    public static readonly BT_MENU = `menu`;
    public static readonly BT_LOGOUT = `logout`;
    public static readonly WHICH_CONTAINS = `which contains the`;
    public static readonly THE = `the`;
    public static readonly LOG_FOLDER = `LOG folder`;
    public static readonly COPYRIGHT = `Copyright`;
    public static readonly COMPANY = `TOKYO SEIMITSU CO., LTD`;
    public static readonly ACCRETECH = `Accretech`;
    public static readonly AUTO_REFRESH = `Refresh per `;
    public static readonly SECONDS = `seconds`;
    public static readonly ALERT = `alert`;
    public static readonly FORCE_REFRESH = `FORCE REFRESH`;
    public static readonly INPUT_BUCKET_URL = `Input the Bucket URL`;

    public static readonly S3_TABLE_HEADER = `AWS S3 File Processing Status`;
    public static readonly S3_TABLE_SUB_HEADER = `Log Files`;

    public static readonly UPLOADING_WARNING = `Files will start uploading to S3 and the Application will have disabled functionality.`;
    public static readonly UPLOADING_QUESTION = `Do you want to upload files to S3?`;
    public static readonly UPLOADING_TITLE = `Equipment's Logs Information`;
    public static readonly UPLOADING_TABLE_TITLE = `Files Upload Progressing`;
    public static readonly UPLOADING_TABLE_SUB_TITLE = `Log files`;
    public static readonly SELECT_FOLDER = `Select the folder which contains`;
    public static readonly AND = `and`;
    public static readonly TO = `to`;
    public static readonly IMPORT = `import`;
    public static readonly HASHTAG = `#`;
    public static readonly LOG = `LOG`;
    public static readonly ofFile = `${this.HASHTAG} of FILES`;
    public static readonly NO_FILE = `No file is being processed now.`;
    public static readonly PROCCESSING = `Processing...`;
    public static readonly SIGNOUT = `Sign out`;
    public static readonly USERNAME_LABEL = `Username`;
    public static readonly USERNAME_PLACEHOLDER = `Username...`;
    public static readonly PASSWORD_LABEL = `Password`;
    public static readonly PASSWORD_PLACEHOLDER = `Password...`;
    public static readonly CLOSE_PARENTHESIS = ')';
    public static readonly OPEN_PARENTHESIS = '(';
    public static readonly DOT = '.';
    public static readonly ASTERISK = '*';
    public static readonly COLON = ':';
    public static readonly TILDE = '~';
    public static readonly BACKDOOR_TITLE = 'BACKDOOR';
    public static readonly BACKDOOR_CONTENT_TITLE = 'CHANGE S3 BUCKET';
    public static readonly BUCKET_SQLITE_LABEL = 'SQLite Bucket URL';
    public static readonly BUCKET_BLOCKING_LABEL = 'Blocking Bucket URL';
    public static readonly MACHINE_MANAGEMENT_HEADING = 'Machine Management';
    public static readonly SEARCHING_PLACEHOLDER = 'Please enter your keywords...';
    public static readonly CREATE_MACHINE_LABEL = 'Create Machine';
    public static readonly PLUS_CHARACTER = '+';
    public static readonly NO_MACHINE_TEXT = 'No machine data.';
    public static readonly TIME_ZONE_LABEL = 'Time zone';
    public static readonly OLDEST_LOGTIME_LABEL = 'Oldest Log Time';
    public static readonly LATEST_LOGTIME_LABEL = 'Latest Log Time';
    public static readonly CREATE_MACHINE_TITLE = 'Create Machine';
    public static readonly UPDATE_MACHINE_TITLE = 'Update Machine';
    public static readonly DELETE_MACHINE_TITLE = 'Delete Machine';
    public static readonly MACHINE_ID_LABEL = 'Machine ID';
    public static readonly MACHINE_NAME_LABEL = 'Machine name';
    public static readonly MODEL_LABEL = 'Model';
    public static readonly CUSTOMER_NAME_LABEL = 'Customer name';
    public static readonly ACTION_LABEL = 'Action';
    public static readonly MACHINE_ID_PLACEHOLDER = 'Enter Machine ID...';
    public static readonly MACHINE_NAME_PLACEHOLDER = 'Enter Machine name...';
    public static readonly MODEL_PLACEHOLDER = 'Select Model...';
    public static readonly CUSTOMER_NAME_PLACEHOLDER = 'Enter Customer name...';
    public static readonly TIME_ZONE_PLACEHOLDER = 'Select Time zone...';
    public static readonly DELETE_CONFIRMATION_QUESTION_TEXT = 'Are you sure to delete the following machine?';
    public static readonly DELETE_DATA_AND_LOGS_TOGETHER_TEXT = 'All the data and logs of this machine will be deleted together.';
}

export default TextCommon;
