/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
    BucketType,
    ICompletedScanningType,
    IFetchS3Type,
    INotifyType,
    ISetBucket,
    IThrowErrorUploadType,
    ITriggerErrorType,
    ITriggerLoadingType,
    IUploadType,
} from '../types';
import { AppAction, AuthenticatedPayload } from '../store';
import { AmplifyS3Config } from 'src/custom/type';

const setNotifyType = (payload: INotifyType): AuthenticatedPayload => ({ type: AppAction.SET_NOTIFY, payload });

const setConfirmUploadType = (): AuthenticatedPayload => ({ type: AppAction.CONFIRM_UPLOAD });

const setCancelUploadType = (): AuthenticatedPayload => ({ type: AppAction.CANCEL_UPLOAD });

const setTriggerErrorType = (payload: ITriggerErrorType): AuthenticatedPayload => ({ type: AppAction.TRIGGER_ERROR, payload });

const setCompletedScanningType = (payload: ICompletedScanningType): AuthenticatedPayload => ({ type: AppAction.COMPLETED_FILES_SCANNING, payload });

const setTriggerLoadingType = (payload: ITriggerLoadingType): AuthenticatedPayload => ({ type: AppAction.TRIGGER_LOADING, payload });

const setFetchS3Type = (payload: IFetchS3Type): AuthenticatedPayload => ({ type: AppAction.FETCH_S3, payload });

const setCompletedUploadType = (payload: INotifyType): AuthenticatedPayload => ({ type: AppAction.COMPLETED_UPLOAD, payload });

const setClearErrorType = (): AuthenticatedPayload => ({ type: AppAction.CLEAR_ERROR });

const setUploadType = (payload: IUploadType) => ({ type: AppAction.SET_UPLOAD, payload });

const setThrowErrorUploadType = (payload: IThrowErrorUploadType) => ({ type: AppAction.THROW_ERROR_UPLOAD, payload });

const setSqliteBucketType = (payload: AmplifyS3Config<BucketType>[]) => ({ type: AppAction.SET_SQLITE_BUCKET, payload });

const setPageId = (payload: string) => ({ type: AppAction.UPDATE_PAGE_ID, payload });

const setIsShowMenu = (payload: boolean) => ({ type: AppAction.SET_IS_SHOW_MENU, payload });

const setIntervalId = (payload: undefined | NodeJS.Timer) => ({ type: AppAction.SET_INTERVAL_ID, payload });

const setAuto = (payload: boolean) => ({ type: AppAction.SET_AUTO, payload });

const setTimer = (payload: number) => ({ type: AppAction.SET_TIMER, payload });

const setIsOpenBackDoor = (payload: boolean) => ({ type: AppAction.SET_IS_OPEN_BACKDOOR, payload });

const setIsOpenCreateMachineDialog = (payload: boolean) => ({ type: AppAction.SET_IS_OPEN_CREATE_MACHINE_DIALOG, payload });

const setIsOpenUpdateMachineDialog = (payload: boolean) => ({ type: AppAction.SET_IS_OPEN_UPDATE_MACHINE_DIALOG, payload });

const setIsOpenDeleteMachineDialog = (payload: boolean) => ({ type: AppAction.SET_IS_OPEN_DELETE_MACHINE_DIALOG, payload });

const setIsOpenDeleteConfirmationDialog = (payload: boolean) => ({ type: AppAction.SET_IS_OPEN_DELETE_CONFIRMATION_DIALOG, payload });

const setIsOpenBackdrop = (payload: boolean) => ({ type: AppAction.SET_IS_OPEN_BACKDROP, payload });

const setError = (payload: string) => ({ type: AppAction.SET_ERROR_MESSAGE, payload });

export {
    setNotifyType,
    setConfirmUploadType,
    setCancelUploadType,
    setTriggerErrorType,
    setTriggerLoadingType,
    setCompletedScanningType,
    setFetchS3Type,
    setCompletedUploadType,
    setClearErrorType,
    setUploadType,
    setThrowErrorUploadType,
    setSqliteBucketType,
    setPageId,
    setIsShowMenu,
    setIntervalId,
    setAuto,
    setTimer,
    setIsOpenBackDoor,
    setIsOpenCreateMachineDialog,
    setIsOpenUpdateMachineDialog,
    setIsOpenDeleteMachineDialog,
    setIsOpenDeleteConfirmationDialog,
    setIsOpenBackdrop,
    setError,
};
