enum Action {
    FETCH_S3 = 'FETCH_S3',
    COMPLETED_FILES_SCANNING = 'COMPLETED_FILES_SCANNING',
    CLEAR_ERROR = 'CLEAR_ERROR',
    TRIGGER_ERROR = 'TRIGGER_ERROR',
    TRIGGER_LOADING = 'TRIGGER_LOADING',
    SET_NOTIFY = 'SET_NOTIFY',

    CANCEL_UPLOAD = 'CANCEL_UPLOAD',
    CONFIRM_UPLOAD = 'CONFIRM_UPLOAD',
    SET_UPLOAD = 'SET_UPLOAD',
    COMPLETED_UPLOAD = 'COMPLETED_UPLOAD',
    THROW_ERROR_UPLOAD = 'THROW_ERROR_UPLOAD',

    SET_SQLITE_BUCKET = 'SET_SQLITE_BUCKET',
    UPDATE_PAGE_ID = 'UPDATE_PAGE_ID',
    SET_IS_SHOW_MENU = 'SET_IS_SHOW_MENU',
    SET_IS_OPEN_BACKDOOR = 'SET_IS_OPEN_BACKDOOR',
    SET_INTERVAL_ID = 'SET_INTERVAL_ID',
    SET_AUTO = 'SET_AUTO',
    SET_TIMER = 'SET_TIMER',
    SET_IS_OPEN_CREATE_MACHINE_DIALOG = 'SET_IS_OPEN_CREATE_MACHINE_DIALOG',
    SET_IS_OPEN_UPDATE_MACHINE_DIALOG = 'SET_IS_OPEN_UPDATE_MACHINE_DIALOG',
    SET_IS_OPEN_DELETE_MACHINE_DIALOG = 'SET_IS_OPEN_DELETE_MACHINE_DIALOG',
    SET_IS_OPEN_DELETE_CONFIRMATION_DIALOG = 'SET_IS_OPEN_DELETE_CONFIRM_DIALOG',
    SET_IS_OPEN_BACKDROP = 'SET_IS_OPEN_BACKDROP',
    SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE',
}

export default Action;
