import React from 'react';

export enum ICSSWordBreak {
    breakAll = 'break-all',
    breakWord = 'break-word',
    keepAll = 'keep-all',
    normal = 'normal',
}
export interface TimeZone {
    index: number;
    name: string;
}

export interface IMachineItem {
    id: string;
    name: string;
    timeZone: TimeZone;
    customer: string;
    model: string;
    oldestDatetime: string;
    latestDatetime: string;
}
export interface IMachineMachineListControlProps {
    handleOnChangeSearchVal: (value: string) => void;
}

export interface ISearchControlProps {
    handleOnChangeSearchVal: (value: string) => void;
}

export interface ITimezoneControlProps {
    handleOnChangeTimeZone: (value: number) => void;
}
export interface IMachineGetFromAPI {
    [key: string]: {
        model: string;
        timeZone: number;
        customer?: string;
        name?: string;
    };
}

export interface IMachineListFromApi {
    machines: IMachineGetFromAPI;
}

export interface IMachineInformation {
    id: string;
    timeZone: number;
    model: string;
    [key: string]: any;
}

export interface IModelListFromApi {
    models: string[];
}
export interface ICreatedMachineFromApi {
    id: string;
    model: string;
    db_storage_name: string;
    time_zone: string;
}
export interface IUpdatedMachineFromApi {
    id: string;
    model: string;
    name?: string;
    customer?: string;
    time_zone: string;
}
export interface ICreatedMachineForApi {
    id: string;
    model: string;
    time_zone: number;
}
export interface IUpdatedMachineForApi {
    model: string;
    name?: string;
    customer?: string;
    time_zone: number;
}
export interface IDeletedMachineFromApi {
    id: string;
    model: string;
    name?: string;
    customer?: string;
    time_zone: string;
    db_storage_name: string;
}

export interface IMachineAction {
    index: number;
    iconSrc: string;
    clickEventHandler?: () => void;
    dataTestId: string;
}

export interface IMachineDataRow {
    key: string;
    id: string;
    name: string;
    timeZone: string;
    customer: string;
    model: string;
    oldestDatetime: string;
    latestDatetime: string;
    action: React.ReactNode;
}

export interface IMachineLogInfomation {
    machines: {
        [machineId: string]: {
            oldestDatetime: string;
            latestDatetime: string;
        };
    };
}
