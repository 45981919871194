import { BucketType, EnvironmentMode, BrowserStorageKey, FileOfBucket } from '../types';
import { AmplifyS3Config } from '../custom/type';
import { buildAmplifyS3Config } from '../utils/config.util';

export const MODE = EnvironmentMode.DEV;

export const isDevelopMode = (): boolean => {
    return MODE.toString() === EnvironmentMode.DEV.toString();
};

const MODE_DEV_PREFIX = 'dev-';
const BE_REGION = 'ap-southeast-2';
const S3_SQLITE_BUCKET = 'aims-sqlite';
const S3_BLOCKING_BUCKET = 'aims-client-blocking';

export const COMPLETED_PERCENTAGE_NUM = 100;

export const APP_SCANNING_DELAY_MILLISECOND = 500;
export const APP_INTERVAL_TIME_SECOND = 10;
export const FILE_REFRESH_TIME_SECOND = 1;
export const DEFAULT_FILE_IN_BUCKET_COUNT = 0;
export const DEFAULT_OBJECT_LENGTH = 0;
export const ZERO_NUMBER = 0;
export const CONVERSION_UNIT = 1024;
export const MIN_SIZE_IN_BYTES = 512;
export const BYTE_TO_GIGABYTE = Math.pow(CONVERSION_UNIT, 3);
export const MAX_SIZE_IN_BYTES = 2 * BYTE_TO_GIGABYTE;
export const EMPTY_STRING = '';
export const SECOND_TO_MILLISECOND = 1000;
export const VALID_LOG_FOLDERS = ['TraceLog', 'WaferLog', 'AlarmLog', 'SvLog'];
export const APP_TIMESTREAM_FOLDER = ['SvLog'];
export const APP_ACCEPT_EXTENSION_FILES = ['.db3', '.xml'];
export const FILENAME_BLOCKING_FLAG = 'blocking-ui.flag';
export const CHARACTER_TIMEZONE_GMT = 'GMT';
export const CHARACTER_SLASH = '/';
export const STRING_REGION = 'region';
export const CHARACTER_PERCENTAGE = '%';
export const CHARACTER_APPROXIMATION = '~';
export const NOT_FOUND_INDEX = -1;
export const REGEX_CAMEL_CASE = /(?=[A-Z])/;

const S3_SQLITE_BUCKET_DEV_MODE = MODE_DEV_PREFIX + S3_SQLITE_BUCKET;
const S3_BLOCKING_BUCKET_DEV_MODE = MODE_DEV_PREFIX + S3_BLOCKING_BUCKET;

export const S3_REGION = isDevelopMode() ? BE_REGION : '';
export const APP_SQLITE_BUCKET = isDevelopMode() ? S3_SQLITE_BUCKET_DEV_MODE : S3_SQLITE_BUCKET;
export const APP_BLOCKING_BUCKET = isDevelopMode() ? S3_BLOCKING_BUCKET_DEV_MODE : S3_BLOCKING_BUCKET;

export const APP_SQLITE_CONFIG: AmplifyS3Config<BucketType> = buildAmplifyS3Config(
    BucketType.SQLITE,
    APP_SQLITE_BUCKET,
    BE_REGION,
    BrowserStorageKey.S3_BUCKET_SQLITE,
);

export const APP_BLOCKING_CONFIG: AmplifyS3Config<BucketType> = buildAmplifyS3Config(
    BucketType.BLOCKING,
    APP_BLOCKING_BUCKET,
    BE_REGION,
    BrowserStorageKey.S3_BUCKET_CLIENT_BLOCKING,
);

export const ALL_S3_CONFIGS = [APP_SQLITE_CONFIG, APP_BLOCKING_CONFIG];
export const ALL_S3_BUCKETS = [S3_SQLITE_BUCKET, S3_BLOCKING_BUCKET];
export const HTTP_STATUS_CODE_NOT_FOUND = 404;
export const REGEX_SNAKE_CASE = /([-_][a-z])/g;
export const REGEX_UPPER_CASE_FIRST_LETTER = /\b\w/g;
export const CHARACTER_MINUS = '-';
export const CHARACTER_UNDERLINE = '_';
export const VARIABLE_TYPE_OBJECT = 'object';

export const FileSupport = {
    getMaxSizeInBytes: () => MAX_SIZE_IN_BYTES,
    getMinSizeInBytes: () => MIN_SIZE_IN_BYTES,
};
// Save the reference to FileSupport object on the Window object
if (window.Cypress) {
    window.FileSupport = FileSupport;
}

export const FILE_TABLE_COLUMN_KEY = {
    FILE: 'file',
    FOLDER: 'folder',
    SIZE: 'size',
    PROGRESS: 'progress',
    EQUIPMENT_ID: 'equipmentId',
    DATE: 'date',
};

export const DEFAULT_BUCKET_INFORMATION: FileOfBucket = {
    name: S3_SQLITE_BUCKET_DEV_MODE,
    totalFile: ZERO_NUMBER,
    totalSize: ZERO_NUMBER,
    progress: ZERO_NUMBER,
    objects: [],
    isError: false,
};
